
import { computed, defineComponent, onMounted, ref } from 'vue';

import { emitter } from '../../emitter';

export default defineComponent({
  props: {
    link: {
      type: String,
      required: true,
    },
    open: Boolean,
  },
  setup(props) {
    const open = ref(false);

    // @ts-ignore
    emitter.on('getState', (link: string) => {
      if (props.link == link) {
        emitter.emit('setState', props.open || false);
      }
    });

    // @ts-ignore
    emitter.on('toggleState', (link: string) => {
      if (props.link == link) {
        open.value = !open.value;
      }
    });

    onMounted(() => (open.value = props.open || false));

    const isVisible = computed(() => open.value || false);

    return {
      props,
      isVisible,
    };
  },
});
