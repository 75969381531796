
import { computed, defineComponent, onMounted, ref } from 'vue';
import { useStore } from 'vuex';

import { emitter } from '../../emitter';
export default defineComponent({
  props: {
    link: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const button = ref(null);
    const open = ref(false);

    // @ts-ignore
    emitter.on('setState', (e: { link: string; value: boolean }) => {
      if (e.link == props.link) open.value = e.value;
    });

    onMounted(() => {
      // GET STATE
      emitter.emit('getState', props.link);
    });

    const toggleCollapsible = () => {
      // EMITT
      emitter.emit('toggleState', props.link);
      open.value = !open.value;
    };

    const isVisible = computed(() => open.value || false);

    return {
      props,
      button,
      toggleCollapsible,
      isVisible,
    };
  },
});
